import {slideToggle} from "./shared";

document.addEventListener('DOMContentLoaded', ()=> {
    setTimeout(()=> {
        slideToggle.onLoad(document.querySelectorAll('.js-cookies-manage-dialog-header'));
        slideToggle.emitList(document.querySelectorAll('.js-cookies-manage-dialog-header'));
        closeDialog();
        openDialog();
    }, 200);
});

document.querySelectorAll('.js-cookies-checkbox-input').forEach(el => {
    el.addEventListener('change', (event) => {
        event.stopPropagation();
        event.preventDefault();
    });
});

const openDialog = ()=> {
    const triggers = document.body.querySelectorAll('.js-cookies-manage-link');
    const dialog = document.getElementById('cookiesManageDialog');
    const overlay = document.getElementById('cookiesManageDialogOverlay');

    triggers.forEach(trigger => {
        trigger.addEventListener('click', ()=> {
            dialog.classList.add('is-state-open');
            overlay.classList.add('is-state-visible');

            setTimeout(()=> {
                overlay.classList.add('is-state-open');
            }, 10);
        });
    });
};

const closeDialog = ()=> {
    const triggers = document.querySelectorAll('.js-cookies-manage-dialog-close');
    const dialog = document.getElementById('cookiesManageDialog');
    const overlay = document.getElementById('cookiesManageDialogOverlay');

    triggers.forEach(trigger => {
        trigger.addEventListener('click', ()=> {
            dialog.classList.remove('is-state-open');
            overlay.classList.remove('is-state-open');

            setTimeout(()=> {
                overlay.classList.remove('is-state-visible');
            }, 300);
        });
    });
}
